import React from "react"

import { Layout } from "antd"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Trans, useI18next } from "gatsby-plugin-react-i18next"
import { JesLayout } from "../components/jes-layout"

const NotFoundPage = () => {
  const { t } = useI18next()
  return (
    <JesLayout title={t("Kontakt")}>
      <h1>
        <Trans>404</Trans>.
      </h1>
      <p>
        <Trans i18nKey="404.head">Sorry, die Seite existiert nicht.</Trans>
      </p>
    </JesLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
